<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import {
  required,
} from "vuelidate/lib/validators";

import { getPMApi } from '@/api/pm'




/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",

    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {

    property: {
      agent_name: { required },
      start_date: { required },
      rent: { required },

      owner_name : {required},
      l_payment  :  {required},
    },


    rta_code: { required },
    addr_city: { required },
    tenants: {
      $each: {
        name: { required },
        payment_type: { required },
        rent: { required },
      }
    },

    landlords: {
      $each: {
        name: { required },
        payment_type: { required },
      }
    }
  },


  methods: {

    formSubmit() {

      console.log(this.property)
      this.landlords.map(e => e.landlord_name = e.name)
      this.$v.$touch();
      console.log(this.$v)
      if (this.$v.$invalid == false) {
        let pm = {
          agent_id : this.property.agent_id,
          apt      : this.addr_apt,
          address  : this.addr_address,
          city     : this.addr_city,
          province : this.addr_province,
          post_code: this.addr_postal_code,

          owner_name       : this.property.owner_name,
          l_payment        : this.property.l_payment,
          l_institution    : this.property.l_institution,
          l_transfer       : this.property.l_transfer,
          l_account_number : this.property.l_account_number,


          monthly_manager_commission: this.property.monthly_manager_commission,
          monthly_manger_pay_way    : this.comm_type_rate == true ? 0 : 1,
          is_month_by_month         : this.property.is_month_by_month ? 1 : 0,


          rent                      : this.property.rent,
          rta_code                  : this.rta_code,
          bto_code                  : this.property.bto_code,

          term: {
            start_date: this.property.start_date,
            end_date: this.property.end_date
          },
          landlords: this.landlords,
          tenants  : this.tenants,
        }
        getPMApi().setup_new_rta(pm).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Property is Setup");
            this.$router.push({ name: 'pm-property-list' })
          } else {
            this.$alertify.error("Setup Property failed:" + res.errCode);
          }
        })
      }

    },


    onSelectedAgent(agent) {
      this.property.agent_id = agent.agent.id
      this.property.agent_name = agent.agent.first_name + ' ' + agent.agent.last_name

      getPMApi().get_rta_code({ agent_id: agent.agent.id }).then(res => {
        if (res.errCode == 0) {
          this.rta_code = res.data
        }
      })
    },
    onLandlordAddressSelected(evt) {
      this.addr_address  = evt.street
      this.addr_city     = evt.city
      this.addr_province = evt.province
    },

    onAddLandlord() {
      this.landlords.push({
        name: '',
        landlord_name: '',
        payment_type: '',
        owner_ship: '100',

        institution: '',
        transit: '',
        account: '',
        is_corporatuin: 0,
        owner_t_code  : this.temp_owner_code ++

      })
    },

    onAddTenant() {
      this.tenants.push({
        name: '',
        email: '',
        phone: '',
        payment_type: '',
        institution: '',
        transit: '',
        account: '',
        rent: '',

      })
    },



    onRemoveTenant(idx) {
      this.tenants.splice(idx, 1)
    },
    onRemoveLandlord(idx) {
      this.landlords.splice(idx, 1)
    },

    onSelectBTO(evt) {
      this.property.owner_name = evt.name
      this.property.l_payment  = evt.payment_type
      this.property.bto_code   = evt.owner_t_code
      if (evt.payment_type == 'EFT') {
        this.property.l_institution    = evt.institution
        this.property.l_transfer       = evt.transit
        this.property.l_account_number = evt.account

      }
      console.log(evt, this.property)
    }

  },

  data() {
    return {
      title: "PM",

      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA List",
          href: "/pm/property_list",
        },
        {
          text: "Setup New Property",
          active: true,
        },
      ],

      rta_code: '',
      addr_apt: '',
      addr_city: '',
      addr_province: '',
      addr_postal_code: '',
      addr_address: '',

      tenants: [],

      property: {
        agent_id: '',
        rent: '',
        start_date: '',
        end_date: '',
        agent_name: '',
        is_month_by_month: true,
        monthly_manager_commission: 0,
        monthly_manger_pay_way: 1,
        landloard: {
          name: ''
        },

        l_payment : '',
        l_institution : '',
        l_transfer    : '',
        l_account_number : '',
        bto_code         : '',
        owner_name       : '',

        address          :'',
      },

      landlords: [],


      comm_type_rate: false,
      tenant_name: '',
      tenant_payment_type: 'PAD',
      t_institution: '',
      t_transit: '',
      t_account: '',
      property_address: '',
      searched_property_address: '',
      properties_data: [],
      pma_list_data: [],

      temp_owner_code : 20001,
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    AgentAutoComplete,
    AddressAutoComplete,
    VueBootstrapTypeahead,

  },
  mounted() {

    this.onAddTenant()
    this.onAddLandlord()
  },

  watch: {

    searched_property_address(new_search_name) {
      this.queryProperty(new_search_name)
    },


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Property Manager</label>
                          <AgentAutoComplete v-model="property.agent_name" @onSelected="onSelectedAgent"
                            autocomplete="off"
                            :init_data="{ style_class: { 'form-control':'form-control','is-invalid': $v.property.agent_name.$error }, agent_name: property.agent_name, agent_id: property.agent_id }" />
                          <div v-if="$v.property.agent_name.$error" class="invalid-feedback">
                            <span v-if="!$v.property.agent_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">RTA Code</label>
                          <input type="text" class="form-control" :class="{ 'is-invalid': $v.rta_code.$error }"
                            v-model="rta_code"  />
                          <div v-if="$v.rta_code.$error" class="invalid-feedback">
                            <span v-if="!$v.rta_code.required">This value is required.</span>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col-lg-1">
                        <div class="mb-3">
                          <label class="form-label">Apt</label>
                          <input type="text" class="form-control" v-model="addr_apt" />
                        </div>
                      </div>

                      <div class="col-lg-3">
                        <div class="mb-3">
                          <label class="form-label">Address</label>
                          <AddressAutoComplete v-model="addr_address" :fill_full="false" @onSelected="onLandlordAddressSelected($event)" autocomplete="off" />
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label class="form-label">City</label>
                          <input type="text" class="form-control" v-model="addr_city"
                            :class="{ 'is-invalid': $v.addr_city.$error }" />
                          <div v-if="$v.addr_city.$error" class="invalid-feedback">
                            <span v-if="!$v.addr_city.required">This value is required.</span>
                          </div>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>Province</label>
                          <input type="text" class="form-control" v-model="addr_province" />
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <div class="mb-3">
                          <label>Postal Code</label>
                          <input type="text" class="form-control" v-model="addr_postal_code" />
                        </div>
                      </div>


                    </div>


                  </div>
                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Term Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">Rent</label>
                        <input type="text" v-model="property.rent" class="form-control" placeholder="Enter Amount"
                          :class="{ 'is-invalid': $v.property.rent.$error }" />
                        <div v-if="$v.property.rent.$error" class="invalid-feedback">
                          <span v-if="!$v.property.rent.required">This value is required.</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-designation-input">Start Date</label>
                        <flat-pickr v-model="property.start_date" placeholder="Select a date" class="form-control"
                          :class="{ 'is-invalid': $v.property.start_date.$error }"></flat-pickr>
                        <div v-if="$v.property.start_date.$error" class="invalid-feedback">
                          <span v-if="!$v.property.start_date.required">This value is required.</span>
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label">Management Fee</label><label v-if="comm_type_rate == true">(%)</label>
                        <input type="text" v-model="property.monthly_manager_commission" class="form-control"
                          placeholder="Amount or Rate" />
                      </div>
                    </div>

                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-check-label">By Portion</label>
                        <input type="checkbox" class="form-check mt-3" v-model="comm_type_rate" />
                      </div>
                    </div>

                  </div>
                  <!-- end row-->


                </div>
              </div>
              <!-- end card -->
              <!-- Term Info-->



              <!-- Tenant Block  -->
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        03
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Tenant Info</h5>
                  </div>
                  <div class="flex-grow-2">
                    <button type="button" class="btn btn-primary w-sm" @click="onAddTenant">Add Tenant</button>
                  </div>
                </div>
                <div class="card-body">


                  <div class="row mb-3" v-for="(tenant, idx) in tenants" :key="'tenant_' + idx">
                    <div class="row">
                      <div class="mb-3 col-md-2">
                        <label for="contact-info-email-input">Tenant {{ idx + 1 }}:</label>
                        <input type="text" class="form-control" v-model="tenant.name"
                          :class="{ 'is-invalid': $v.tenants.$each[idx].name.$error }" maxlength="50" />
                        <div v-if="$v.tenants.$each[idx].name.$error" class="invalid-feedback">
                          <span v-if="!$v.tenants.$each[idx].name.required">This value is required.</span>
                        </div>

                      </div>
                      <div class="mb-3 col-md-2">
                        <label for="contact-info-email-input">Email :</label>
                        <input type="text" v-model="tenant.phone" class="form-control" placeholder="Enter Email" />
                      </div>
                      <div class="mb-3 col-md-2">
                        <label for="contact-info-email-input">Phone :</label>
                        <input type="text" v-model="tenant.number" class="form-control" placeholder="Enter Number" />
                      </div>
                      <div class="mb-3 col-md-2">
                        <div class="mb-3">
                          <label for="validationCustom01">PAD Payment</label>
                          <b-form-select v-model="tenant.payment_type" class="form-control"
                            :class="{ 'is-invalid': $v.tenants.$each[idx].payment_type.$error }"
                            :options="[{ value: 'PAD', text: 'PAD' }, { value: 'CHEQUE', text: 'Cheque' }, { value: 'ETransfer', text: 'ETransfer' }]">

                          </b-form-select>
                          <div v-if="$v.tenants.$each[idx].payment_type.$error" class="invalid-feedback">
                            <span v-if="!$v.tenants.$each[idx].payment_type.required">This value is required.</span>
                          </div>
                        </div>

                      </div>

                      <div class="mb-3 col-md-2">
                        <label for="contact-info-email-input">Rent :</label>
                        <input type="text" v-model="tenant.rent"
                          :class="{ 'is-invalid': $v.tenants.$each[idx].rent.$error }" class="form-control"
                          placeholder="Enter Rent" />
                        <div v-if="$v.tenants.$each[idx].rent.$error" class="invalid-feedback">
                          <span v-if="!$v.tenants.$each[idx].rent.required">This value is required.</span>
                        </div>
                      </div>

                      <div class="col-md-1">
                        <div class="row icon-demo-content-10 mb-3 d-flex align-items-start justify-content-md">
                          <label class="form-label"> {{ ' ' }}&nbsp;</label>
                          <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveTenant(idx)">
                            <i class="uil-times font-size-6"></i>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="row" v-if="tenant.payment_type == 'PAD'">
                      <div class="col-md-2">
                        <div class="mb-3">
                          <label for="contact-info-website-input" class="form-label">Institution</label>
                          <input type="text" class="form-control" v-model="tenant.institution"
                            placeholder="  Institution " />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <label for="contact-info-location-input" class="form-label">Transit</label>
                          <input type="text" class="form-control" v-model="tenant.transit"
                            placeholder="Transit Number" />
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="mb-3">
                          <label for="contact-info-location-input" class="form-label">Account</label>
                          <input type="text" class="form-control" v-model="tenant.account"
                            placeholder=" Bank Account" />
                        </div>
                      </div>
                    </div>

                    <div class="row mb-3">
                      <hr>
                    </div>
                  </div>





                </div>
              </div>
              <!-- end card -->


              <!-- Landlord Info -->
              <div class="card border shadow-none">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        04
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Landlord Info</h5>
                  </div>

                  <div class="flex-grow-2">
                    <button type="button" class="btn btn-primary w-sm" @click="onAddLandlord">Add Landlord</button>
                  </div>

                </div>
                <div class="card-body" v-for="(landlord, idx) in landlords" :key="'landlord_' + idx">
                  <div class="row">
                    <div class="col-md-2">
                      <label for="validationCustom04">Name</label>
                      <input type="text" class="form-control" v-model="landlord.name"
                        :class="{ 'is-invalid': $v.landlords.$each[idx].name.$error }" maxlength="50" />
                      <div v-if="$v.landlords.$each[idx].name.$error" class="invalid-feedback">
                        <span v-if="!$v.landlords.$each[idx].name.required">This value is required.</span>
                      </div>
                    </div>
                    <div class="col-md-2">
                      <label>Email</label>
                      <input type="text" class="form-control" v-model="landlord.email" />
                    </div>
                    <div class="col-md-2">
                      <label for="validationCustom04"> Phone</label>
                      <input type="text" class="form-control" v-model="landlord.phone" />

                    </div>

                    <div class="col-md-2">
                      <div class="mb-3">
                        <label for="validationCustom01">Payment</label>
                        <b-form-select v-model="landlord.payment_type" class="form-control"
                          :class="{ 'is-invalid': $v.landlords.$each[idx].payment_type.$error }"
                          :options="[{ value: 'EFT', text: 'EFT' }, { value: 'CHEQUE', text: 'Cheque' }]">

                        </b-form-select>

                        <div v-if="$v.landlords.$each[idx].payment_type.$error" class="invalid-feedback">
                          <span v-if="!$v.landlords.$each[idx].payment_type.required">This value is required.</span>
                        </div>

                      </div>

                    </div>

                    <div class="mb-3 col-md-1">
                      <label class="form-label" for="formrow-customCheck">NR</label>
                      <input type="checkbox" class="form-check mt-2" v-model="landlord.landlord_is_non_resident" />
                    </div>

                    <div class="mb-3 col-md-2">
                      <label class="form-label" for="formrow-customCheck">Owner Portion(%)</label>
                      <input type="text" class="form-control" v-model="landlord.owner_ship" />
                    </div>

                    <div class="col-md-1">
                      <div class="row icon-demo-content-10 mb-3 d-flex align-items-start justify-content-md">
                        <label class="form-label"> {{ ' ' }}&nbsp;</label>
                        <div class="col-xl-3 col-lg-4 col-sm-6" @click="onRemoveLandlord(idx)">
                          <i class="uil-times font-size-6"></i>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label>Address</label>
                        <input type="text" class="form-control" v-model="landlord.landlord_address" />
                      </div>
                    </div>
                      <div class="col-md-2">
                        <label>Email2</label>
                        <input type="text" class="form-control" v-model="landlord.email2" />
                      </div>

                    <div class="col-md-2">
                      <label>Email3</label>
                      <input type="text" class="form-control" v-model="landlord.email3" />
                    </div>

                  </div>


                  <div class="row mb-3" v-if="landlord.payment_type == 'EFT'">
                    <div class="col-md-2">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label"> Institution</label>
                        <input type="text" class="form-control" v-model="landlord.institution"
                          placeholder="Enter Bank  Institution Number" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label"> Transit</label>
                        <input type="text" class="form-control" v-model="landlord.transit"
                          placeholder="Enter Bank Transit Number" />
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label"> Account</label>
                        <input type="text" class="form-control" v-model="landlord.account"
                          placeholder="Enter Bank Account" />
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <hr>
                  </div>

                </div>
              </div>
              <!-- end card -->



              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        05
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">BTO Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-name-input">BTO Name</label>
                        <vue-bootstrap-typeahead v-model="property.owner_name" :remoteMatch="false"  :minMatchingChars="1"
                              :data="landlords" :serializer="s => s.name" :foramterDisplay="s => s.name"  :styleClass="{'form-control' : 'form-control', 'is-invalid': $v.property.owner_name.$error}"
                              @hit="onSelectBTO($event)" autocomplete="off"  />

                        <div v-if="$v.property.owner_name.$error" class="invalid-feedback">
                          <span v-if="!$v.property.owner_name.required">This value is required.</span>
                        </div>

                      </div>
                    </div>
                    <div class="col-lg-2">
                      <div class="mb-3">
                        <label class="form-label" for="gen-info-designation-input">Payment Type</label>
                        <b-form-select v-model="property.l_payment" class="form-control"
                          :class="{ 'is-invalid': $v.property.l_payment.$error }"
                          :options="[{ value: 'EFT', text: 'EFT' }, { value: 'CHEQUE', text: 'Cheque' }]">
                        </b-form-select>
                        <div v-if="$v.property.l_payment.$error" class="invalid-feedback">
                          <span v-if="!$v.property.l_payment.required">This value is required.</span>
                        </div>

                      </div>
                    </div>

                    <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                      <div class="mb-md-0">
                        <label for="contact-info-website-input" class="form-label"> Institution</label>
                        <input type="text" class="form-control" v-model="property.l_institution"
                          placeholder="Enter Bank  Institution Number" />
                      </div>
                    </div>
                    <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label"> Transit</label>
                        <input type="text" class="form-control" v-model="property.l_transfer"
                          placeholder="Enter Bank Transit Number" />
                      </div>
                    </div>
                    <div class="col-md-2" v-if="property.l_payment == 'EFT'">
                      <div class="mb-md-0">
                        <label for="contact-info-location-input" class="form-label"> Account</label>
                        <input type="text" class="form-control" v-model="property.l_account_number"
                          placeholder="Enter Bank Account" />
                      </div>
                    </div>


                   

                  </div>
                  <!-- end row-->


                </div>
              </div>
              <!-- end card -->
              <!-- BTO Info-->

              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm"
                      @click="$router.push({ name: 'pm-property-list' })"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm">
                      Setup RTA
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
